.PlayerProfile{

    .Opta .Opta-H2 .Opta-Title,
.Opta .Opta-H2 .Opta-Title-Extra,
.Opta h2 .Opta-Title,
.Opta h2 .Opta-Title-Extra {
  margin-left: 0px;
}

.Opta .Opta_W .Opta-Profile {
    border: 1px solid #f5f5f5;
    border-radius: 10px;
}

.Opta .Opta-H2 .Opta-Title,
.Opta .Opta-H2 .Opta-Title-Extra,
.Opta h2 .Opta-Title,
.Opta h2 .Opta-Title-Extra {
    background: white;
    color: #ff8e29;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

}

.Opta h2 .Opta-Logo {
    background-image: none;
}

.Opta .Opta-H2,
.Opta h2 {

    height: 44px;
    margin-left: 18px;

}

.Opta .Opta-H2,
.Opta h2 {
    background: white;
    margin-left: 34px;
}


.Opta .Opta-Nav li.Opta-On a {
    color: #fff;
    background-color: #ff8e29;
}

.Opta .Opta-MatchHeader td.Opta-Team {
    font-size: 20px;
    background: #FFFFFF;
    color: black;
}

.Opta .Opta_F_MP .Opta-TabbedContent table.Opta-MatchHeader .Opta-Score .Opta-Team-Score {

    padding: 1px 0px;
    font-size: 13px;
    font-weight: bold;



}

.Opta .Opta-H4,
.Opta h4 {

    background: #ff8e29;

}

.Opta .Opta_F_MP .Opta-previous .Opta-Scoreline td.Opta-Score div span:not(.Opta-Hidden) {

    font-size: 15px;
    font-weight: bold;
}

.Opta .Opta_F_MP .Opta-Fixture-List tr.Opta-Scoreline td.Opta-Team.Opta-Home,
.Opta .Opta_F_MP .Opta-Fixture-List tr.Opta-Scoreline td.Opta-Team.Opta-Away {
    font-weight: bold;
    width: 45%;
    font-size: 15px;
}

.Opta .Opta_F_MP .Opta-Matchdata {
    color: black;
}

.Opta>.Opta_W>div {
   
    padding: 11px 0 10px 0;
    
}

}