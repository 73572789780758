
.teamS{

    .Opta-Stats-Bars {
        background: #FFFFFF;
        position: relative;
        top: 10px;
        left: -11px;
    }

    .Opta table {
    
        width: 106%;
        
    }
    
    .Opta .Opta-Dropdown>.Opta-Nav h3 {
        background-color: #E85720;
    
    }
    
    .Opta .Opta-Stats-Bars .Opta-Bars-Full .Opta-Percent.Opta-Home {
    
        background: #0073a0;
    
    }
    
    .Opta .Opta-Stats-Bars .Opta-Bars-Full .Opta-Percent.Opta-Away {
        background: #e20023
    }
    
    .Opta .Opta-Stats-Bars td.Opta-Outer {
        font-weight: 100;
        color: black;
        font-size: 13px;
    }
    
    .Opta .Opta-Stats-Bars .Opta-Bars-Full {
    
        border-radius: 7px;
    
    }
    
    .Opta .Opta-TimeControls .Opta-PeriodButtons button.Opta-On,
    .Opta .Opta-TimeControls .Opta-PeriodButtons button.Opta-On:hover {
        background-color: #e85720;
    border-radius: 10px;
    color: #f5f5f5;
    font-weight: 700;
    }
    
    .Opta .Opta-TimeControls .Opta-PeriodButtons button {
        background-color: white;
    border-radius: 10px;
    color: #6e6e6e;
    font-weight: 700;
    border: 1.5px solid #e85720;
    -webkit-tap-highlight-color: transparent;
    }
    
    .Opta-Cf .Opta-js-main {
        background: #FFFFFF
    }
    
    .Opta .Opta_F_MP div.Opta-Dropdown {
        padding-left: 7px;
        padding-right: 7px;
        -webkit-tap-highlight-color: transparent;
        padding-top: 4px;
    }
    
    .Opta .Opta-TimeControls .Opta-PeriodButtons {
    
        background: white;
    }
    
    .Opta .Opta-Stats-Bars .Opta-Bars-Full {
    
        height: 6px;
    
    }

    .Opta.Opta-Narrow .Opta-Nav a {
        border-radius: 0px; 
         margin: 0px; 
    }

    .Opta .Opta-Dropdown>.Opta-Nav li a {
        width: 100%;
    }
    .Opta.Opta-Narrow .Opta-Nav a {
        // border-radius: 9px;
        font-weight: 12px;

        
    }

    .Opta.Opta-Narrow .Opta-Nav a {
        // border-radius: 9px;
        font-size: 13px;
        font-weight: 700;
    }

    .Opta tbody th {
        color: #464646;
    }
    .Opta .Opta-Stats-Bars th {
        font-size: 13px;
        position: relative;
        top: 10px;
    }

    .Opta .Opta-Stats-Bars th {
        padding: 25px 6px 0px 6px;
    }

    .Opta .Opta-Stats-Bars th {
        height: 10px;
        padding-top: 10px;
    }

    .Opta .Opta-Stats-Bars td.Opta-Outer {
        width: 10%;
    }

    .Opta td {
        
        height: 0px;
        
    }

    .Opta-Outer {
        color: #464646 !important;
        font-weight: 400 !important;
    }

    .Opta>.Opta_W {
        
        margin: 0px;

    }

    .Opta p.Opta-Load {
        position: relative;
        bottom: 350px;
    }


    .Opta .Opta-Dropdown>.Opta-Nav li a:hover {
        background-color: transparent;
        will-change: filter;
    }

    .Opta .Opta-Dropdown>.Opta-Nav li:nth-child(even) a:hover {
        background-color: transparent;
        will-change: filter;
    }

    .Opta .Opta-Dropdown>.Opta-Nav ul {
        -webkit-box-shadow: 3px -2px 6px rgb(0 0 0 / 10%);
    }

    .Opta-js-graphs.Opta-Cf.Opta-Dropdown {
        overflow: hidden;
    }
    .Opta-Narrow .Opta-TimeControls .Opta-PeriodButtons.Opta-Periods-2 button {
        -webkit-tap-highlight-color: transparent;
    }

    //Adi design drodwon changes
    .Opta .Opta-Nav {
        background-color: #fff;
    }


    .Opta .Opta-Dropdown>.Opta-Nav li:nth-child(even) a {
        background-color: #fff;
    }

    .Opta .Opta-Dropdown>.Opta-Nav li a {
        background-color: #f4f4f4;
    }

    .Opta-Open {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .Opta-Exp {
        border-radius: 14px;
    }

    .Opta .Opta-Dropdown>.Opta-Nav ul {
        border: 1px solid #f5f5f5;
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
    }

    .Opta .Opta-Nav {
        position: relative;
        background-color: #fff;
        top: 2px;
    }

    .Opta h3 {
       
        height: 50px;
        
    }

    .Opta-Title{
        position: relative;
        top: 3px;
    }

    .Opta .Opta-Exp .Opta-Icon:after {
        
        top: calc(50% - 4px);

    }

    // .Opta p:last-child {
    //     padding: 10px;
    //     text-align: center;
    //     padding-top: 20px;
    //     font-weight: bold;
    //     font-size: 20px;
    //     color: #E85720;
    //     background-position: center center;
    //         background-image: url(../assets/images/football_error.svg);
    //         background-repeat: no-repeat;
    //         background-size: auto;
    //         height: 70vh;
    //         overflow: hidden;
    // }

    .Opta>.Opta_W>div {
        
        padding: 0 0px 40px 0px;
        
    }

    .Opta-error{
        color: white;
        background-position: center center;
        background-image: url(../assets/images/football_error.svg);
        background-repeat: no-repeat;
        background-size: auto;
        height: 100vh;
        }

        .Opta-error::after{
            content: "Uh oh!\nOur crew has returned exhausted and unsuccessful. Please try again.";
            font-size: 20px;
            position: relative;
            top: 500px;
            color: #E85720;
            left: 140px;
            font-weight: bold;
            }

        .Opta p:last-child {
            color: white;
        }

}