.LeaguePlayerStats{


    .Opta .Opta-Dropdown>.Opta-Nav h3 {
        background-color: #E85720;
        color: #fff;
        -webkit-tap-highlight-color: transparent;
    }

    .Opta div.Opta-Dropdown:last-child {
        padding: 19px;
        padding-top: 5px;
    }

    .Opta.Opta-Narrow .Opta-Nav a {
        border-radius: 0px;
        font-size: 12px;
        margin-left: 0px;
        margin: 0.5px;
        padding-left: 16px;
    }

    

    .Opta .Opta-Image-Player-Medium {
        border-radius: 10px;
        position: relative;
        left: 0px;
    }

    .Opta img {
        border-radius: 5px;
    }

    .Opta .Opta-Ranking .Opta-FeatureHeader td, .Opta .Opta-Ranking .Opta-FeatureHeader th {
        background-color: #07639D;
        
    }

    .Opta .Opta-Ranking .Opta-FeatureHeader td .Opta-Team{
        color: white;
        
    }

    .Opta .Opta-Ranking .Opta-FeatureHeader td .Opta-PlayerName{
        font-weight: bold;
    }

    .Opta .Opta-Ranking .Opta-Rank {
        width: 35px;
        text-align: center;
    }

    .Opta-Narrow .Opta-Ranking .Opta-Score {
        width: 50px;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
    }

    .Opta-Narrow .Opta-Ranking .Opta-Image {
        width: 65px;
        position: relative;
        left: 0px;
    }

    .Opta td {
        
        height: 51px;
        
    }

    .Opta-Narrow .Opta-Ranking .Opta-Score {
        width: 50px;
        position: relative;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .Opta .Opta-Ranking .Opta-Rank {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .Opta table {
        border-collapse: separate;
        border-spacing: 0 10px;
        padding-top: 15px;
    }

    .Opta .Opta-Odd td{
        background-color: #f5f5f5;
    }

    .Opta .Opta-Ranking .Opta-FeatureHeader td{
        border: none;
        color: white;
    }

    .Opta h3>span{
        padding: 0px 20px;
    }

    .Opta img {
        left: 7px;
    position: relative;
    top: 3px;
    }


    //New CSS

    .Opta td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        position: relative;
        left: -1px;
        text-align: center;
    }

    .Opta .Opta-Image-Team-Medium {
        max-width: 40px;
        max-height: 40px;
        position: relative;
        left: -1px;
    }

    .Opta .Opta-Ranking .Opta-FeatureHeader td.Opta-Score div{
        font-weight: bold;
    }

    .Opta .Opta-Ranking .Opta-Games {
        font-size: 14px;
        text-align: center;
        
    }

  

    .Opta .Opta-Dropdown>.Opta-Nav li a:hover {
        background-color: transparent;
        will-change: filter;
    }

    .Opta .Opta-Dropdown>.Opta-Nav li:nth-child(even) a:hover {
        background-color: transparent;
        will-change: filter;
    }

    .Opta td {
        padding: 2px;
    }



    // Adi changes

    .Opta .Opta-Nav {
        background-color: #fff;
    }


    .Opta .Opta-Dropdown>.Opta-Nav li:nth-child(even) a {
        background-color: #fff;
    }

    .Opta .Opta-Dropdown>.Opta-Nav li a {
        background-color: #f4f4f4;
        font-weight: bold;
        color: #404040;
    }

    .Opta-Open {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .Opta-Exp {
        border-radius: 10px;
    }

    .Opta .Opta-Dropdown>.Opta-Nav ul {
        border: none;
    }

    .Opta .Opta-Nav {
        position: relative;
        background-color: #fff;
        top: 5px;
        -webkit-tap-highlight-color: transparent;
    }

    .Opta h3 {
       
        height: 45px;
        
    }

    .Opta-Title{
        position: relative;
        top: 0px;
    }

    .Opta .Opta-Exp .Opta-Icon:after {
        
        top: calc(50% - 8px);

    }

    .Opta .Opta-Dropdown>.Opta-Nav ul {
        // -webkit-box-shadow: 3px -2px 6px rgb(0 0 0 / 10%);
    }

    .Opta .Opta-Ranking .Opta-FeatureHeader td .Opta-PlayerName a{
        font-size: 18px;
    }
    .Opta .Opta-Ranking .Opta-FeatureHeader td.Opta-Games{
        font-size: 18px;
    }

    .Opta .Opta-Image-Team-Small {
        left: -2px !important;
    }
    .Opta .Opta-Ranking .Opta-FeatureHeader td.Opta-Score{
        font-size: 18px;
    }

    .Opta .Opta-Image-Player-Small {
        max-width: 35px;
        max-height: 40px;
    }

    .Opta .Opta-Image-Team-Small {
        max-width: 25px;
        max-height: 30px;
    }



    //New Design

   .Opta .Opta-Ranking .Opta-FeatureHeader td .Opta-PlayerName a{
        color: black;
        font-size: 14px;
        font-weight: bold;
    }

    .Opta .Opta-Ranking .Opta-FeatureHeader td .Opta-Team {
        color: grey !important ;
        font-size: 13px;
    }

     .Opta .Opta-Ranking .Opta-FeatureHeader td.Opta-Score {
        color: black;
    }

    .Opta .Opta-Ranking .Opta-FeatureHeader td {
        color: #fff;
        border-bottom: 1.5px solid #EEEEEE;
        border-radius: 0px;
        background: white;
        padding-bottom: 8px;    
    }

    .Opta .Opta-Ranking .Opta-Rank {
        border-bottom-left-radius: 0;
    }

 .Opta td:last-child {
        border-bottom-right-radius: 0px;
    
    }

   .Opta .Opta-Odd td {
        background-color: white;
    }

     .Opta img {
        top: -2px;
    }

     .Opta td {
        border-bottom: 1.5px solid #EEEEEE;
        border-bottom-left-radius: 0px;
        background-color: white;
        padding-bottom:8px ;
    }

    .Opta .Opta-Ranking .Opta-FeatureHeader td.Opta-Games {
        color: black;
    }

    .Opta .Opta-Ranking .Opta-Rank {
        display: none;
    }

    .Opta img {
        left: 0px;
        
    }

    .Opta .Opta-Image-Team-Medium {
        left: -1px;
        max-height: 30px;
        max-width: 25px;
        position: relative;
    }

    .Opta .Opta-Image-Player-Small {
        max-height: 50px;
        max-width: 30px;
        border-radius: 10px;
    }

    .Opta abbr {
        color: black;
    }

    .Opta .Opta-Ranking .Opta-FeatureHeader td.Opta-Score div {
        color: black;
    }

    .Opta .Opta-Image-Player-Medium {
        max-width: 30px;
        max-height: 50px;
    }
    .Opta a {
        color: black;
        font-weight: bold;
        font-size: 14px;
    }

    .Opta td:last-child {
       
        color: grey;
        
    }

    .Opta table {
        padding-left: 5px;
        padding-right: 5px;
    }
    .Opta .Opta-Dropdown>.Opta-Nav ul {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }


    //New drowdown color instead orange

    .Opta .Opta-Exp .Opta-Icon:after {
        
        filter: grayscale(1) invert(1);
        
    }

    .Opta .Opta-Dropdown>.Opta-Nav h3 {
        border: 1.5px solid #119AE8;
    }

    .Opta h3>span {
        color: #119AE8;
    }

    .Opta .Opta-Dropdown>.Opta-Nav h3 {
        
        background-color: #B3E3EE;
        
    }

    .Opta-Exp {
        border-radius: 10px;
    }

    .Opta .Opta-Dropdown>.Opta-Nav ul {
       
        top: 0px;
        
    }

    .Opta-error{
        color: white;
        background-position: center center;
        background-image: url(../assets/images/football_error.svg);
        background-repeat: no-repeat;
        background-size: auto;
        height: 100vh;
        }
        .Opta-error::after{
            content: "Uh oh!\nOur crew has returned exhausted and unsuccessful. Please try again.";
            font-size: 20px;
            position: relative;
            top: 500px;
            color: #E85720;
            left: 140px;
            font-weight: bold;
            }

        .Opta p:last-child {
            padding: 10px;
            color: white;
        }
}