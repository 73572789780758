
.scroll{
    scrollbar-width: 0 !important;

    .scroll::-webkit-scrollbar{
        display: none;
      }


      .Opta .Opta-H2 .Opta-Title,
      .Opta .Opta-H2 .Opta-Title-Extra,
      .Opta h2 .Opta-Title,
      .Opta h2 .Opta-Title-Extra {
        background: white;
        color: #ff8e29;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
      
      }
      
      .Opta h2 .Opta-Logo {
        background-image: none;
      }
      
      .Opta .Opta-H2,
      .Opta h2 {
      
        height: 44px;
        margin-left: 18px;
      
      }
      
      .Opta .Opta-H2,
      .Opta h2 {
        background: white;
      }
      
      .Opta div.Opta-Even,
      .Opta li.Opta-Even,
      .Opta .Opta-Even th,
      .Opta .Opta-Even td,
      .Opta .Opta-Striped li:nth-child(even),
      .Opta .Opta-Striped tbody tr:nth-child(even) th,
      .Opta .Opta-Striped tbody tr:nth-child(even) td {
        background-color: rgb(0 0 0 / 10%);
      }
      
      
      
      .Opta_F_C .Opta-comment {
        
        font-weight: 500 !important;
      }
      
      .Opta .Opta-H2 .Opta-Title,
      .Opta .Opta-H2 .Opta-Title-Extra,
      .Opta h2 .Opta-Title,
      .Opta h2 .Opta-Title-Extra {
        margin-left: 53px;
      }
      
      .Opta-goal .Opta-Time {
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black !important;
      }
      
      .Opta-Time {
        background: #EEEEEE;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
      
      .Opta_F_C .Opta-Time {
        border-radius: 5px;
        color: #000;
        font-weight: 700;
        height: 39px;
        width: 43px;
        position: relative;
        left: 8px;
      
      }
      
      .Opta_F_C .Opta-Events span.Opta-Event {
        margin: 8px -1px;
      }
      
      .Opta_F_C .Opta-comment {
        margin-top: 7px;
        color: #6E6E6E;
      }
      
      .Opta-goal {
        background-color: #259C45 !important;
      }
      
      .Opta-goal .Opta-Time {
        margin-left: -1px;
      }
      
      .Opta_F_C .Opta-Striped li {
        padding: 27px 5px 32px 11px;
      }
      
      .Opta_F_C .Opta-Events span.Opta-Event {
        margin: 13px 4px;
      }
      
      .Opta_F_C .Opta-comment {
        margin-top: 3px;
      }
      
      .Opta_F_C .Opta-Striped li.Opta-goal .Opta-comment {
        color: #FFFFFF;
        font-weight: bold !important;
      }
      
      // .Opta p:last-child {
      //   background-position: center center;
      //   padding: 40px;
      //   background-image: url(../assets/images/football.gif);
      //   background-repeat: no-repeat;
      //   background-size: contain;
      //   font-size: 0;
      //   padding: 40px;
      //   height: 100vh;
      // }

      .Opta>.Opta_W {
        
        margin: 0px;

    }

    .Opta p.Opta-Load {
      position: relative;
      bottom: 350px;
  }

  .Opta_F_C .Opta-comment {
    
    margin: 0 40px 0px 83px;
    
}

.Opta-error{
  color: white;
  background-position: center center;
  background-image: url(../assets/images/football_error.svg);
  background-repeat: no-repeat;
  background-size: auto;
  height: 100vh;
  }

      
//   .Opta p:last-child {
//     padding: 10px;
//     text-align: center;
//     padding-top: 27px;
//     font-weight: bold;
//     font-size: 20px;
//     color: #E85720;
//     background-position: center center;
//         background-image: url(../assets/images/football_error.svg);
//         background-repeat: no-repeat;
//         background-size: auto;
//         height: 80vh;
// }


}