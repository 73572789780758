.TnC{
    p{
        font-family: 'Poppins', sans-serif;
        color: #6E6E6E;
        font-size: 15px;
        padding-bottom: 10px;
    }

    strong{
        color: #0A1015;
    }
}