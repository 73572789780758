.Opta p.Opta-Load {
    background-image: url(../assets/images/loaderTransparent.gif);
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    font-size: 0px;
    padding: 70px 11px 41px 11px;
    margin: 0px 0px;
    height: 101vh;
}

.Opta p {
    line-height: 1;
}