.FAQ{
    p{
        font-family: 'Poppins', sans-serif;
        color: #6E6E6E;
        font-size: 15px;
    }

    strong{
        color: #0A1015;
    }

    h1{
        margin-top: 11px;
    }
}