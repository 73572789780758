.wrapper {
    align-items: start;
    display: flex;
    /* padding-top: 30px; */
    height: auto;
    justify-content: center;
    width: 100vw;
    background: #0A1015;
    font-family: 'Poppins', sans-serif;
    -webkit-tap-highlight-color: transparent;
}

.accordian {
    width: 500px;
    padding: 15px;
}

.item {
    background: white;
    margin-bottom: 15px;
    padding: 10px 20px;
    border-radius: 15px;
}

.title {
    color: #222020;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
}

.plus {
    font-size: 26px;
    position: relative;
    left: 6px;
    color: #00c4ff;
}

.content {
    color: #6E6E6E;
    max-height: 0;
    overflow: hidden;
    /* transition: all 0.5s ease-in-out; */
}

.content.show {
    font-size: 14px;
    height: auto;
    max-height: 9999px;
    position: relative;
    /* transition: all .5s ease-in-out; */
    bottom: 7px;
}


/* .content {
    color: #6E6E6E;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
    font-size: 14.5px;
    height: auto;
    max-height: 9999px;
    position: relative;
    transition: all .5s cubic-bezier(1, 0, 1, 0);
    bottom: 4px;
} */