.standings{

    .Opta .Opta-Table-Scroll-One-Liner {
        white-space: nowrap;
        padding: 7px;
        margin: -1px;
    }

    .Opta .Opta-Table-Scroll table {
        border-collapse: separate;
        border-spacing: 0;
        overflow: hidden;
        border-radius: 10px;
    }

    // .Opta thead th{
    //     background-color: #E85720;
    //     color: white;
    //     font-weight: bold;
    // }

    .Opta .Opta_F_S.Opta_F_S_F .Opta-Team {
        
        text-align: center;
    }

    .Opta .Opta-Record {
        width: 110%;
        min-width: 133px;
        height: 47px;
        border: 1px solid transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Opta .Opta-Record>span {
        display: flex;
        height: 20px;
        text-align: center;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }

    .Opta td:last-child {
        text-align: center;
    }

    .Opta td {
        text-align: center;
        border-bottom: 1.5px solid #f5f5f5;
        padding-bottom: 15px;
        padding-top: 15px;
    }

    .Opta .Opta_W .Opta-Tooltip>div {
        display: none;
    }

    .Opta .Opta_W .Opta-Tooltip>span:after {
        display: none;
    }

    .Opta th{
        padding: 0px;
        height: 43px;
    }


    //New Standings

    .Opta .Opta-Image-Team-Small {
        max-width: 26px;
        max-height: 26px;
    }

    .Opta h3 {
        background: white;
        color: #000;
        border: none;
    }

    .Opta thead th {
        background-color: white;
        color: black;
        font-weight: 700;
        border-bottom: 3px solid #EEEEEE;
        text-align: center;
    }

    .Opta .Opta_F_S.Opta_F_S_N table .Opta-Team {
        width: 20%;
        color: black;
        font-weight: bold;
        margin-left: 7px;
        text-align: center;
    }

    .Opta .Opta-Striped tbody tr:nth-child(even) td {
        background-color: white;
    }

    .Opta>.Opta_W>div {
        padding: 6px;
    padding-left: 14px;
    padding-right: 14px;
    }

    .Opta abbr {
        font-size: 13px;
    }

    .Opta .Opta-Striped tbody tr:nth-child(even) th{
        background: white;
    }


    .Opta .Opta-Table-Scroll td.Opta-Table-Scroll-Fixed-Column-Last {
        border-right: 1px solid white;
    }

    .Opta .Opta-Table-Scroll td.Opta-Table-Scroll-Scrolled-Column-First {
        padding-left: 6px;
        border-right: none;  
      }

      .Opta .Opta-Table-Scroll th.Opta-Table-Scroll-Scrolled-Column-First{
        padding-left: 16px;
      }

      .Opta .Opta-Table-Scroll th.Opta-Table-Scroll-Fixed-Column-Last{
        border-right: none;
      }

      .Opta tbody th {
        text-align: center;
    }

    .Opta tbody th {
        border-bottom: 1.5px solid #f5f5f5;
    }

    .Opta h3>span{
        position: relative;
    right: 10px;
    }

    .Opta h3 {

        // border-bottom: 1.8px solid #a4a4a4;
        width: 96%;
        position: relative;
        left: 7px;
    }

    .Opta-error{
        color: white;
        background-position: center center;
        background-image: url(../assets/images/football_error.svg);
        background-repeat: no-repeat;
        background-size: auto;
        height: 100vh;
        }

        .Opta-error::after{
            content: "Uh oh!\nOur crew has returned exhausted and unsuccessful. Please try again.";
            font-size: 20px;
            position: relative;
            top: 500px;
            color: #E85720;
            left: 140px;
            font-weight: bold;
            }

        .Opta p:last-child {
           
            color: white;
        }

}