
.SeaonTeamStats{

    .Opta-Stats-Bars {
        background: #FFFFFF;
        position: relative;
        top: 10px;
    }
    
    .Opta .Opta-Dropdown>.Opta-Nav h3 {
        background-color: #E85720;
        -webkit-tap-highlight-color: transparent;
    
    }
    
    .Opta .Opta-Stats-Bars .Opta-Bars-Full .Opta-Percent.Opta-Home {
    
        background: #0073a0;
    
    }
    
    .Opta .Opta-Stats-Bars .Opta-Bars-Full .Opta-Percent.Opta-Away {
        background: #e20023
    }
    
    .Opta .Opta-Stats-Bars td.Opta-Outer {
        font-weight: 100;
        color: black;
        font-size: 14px;
    }
    
    .Opta .Opta-Stats-Bars .Opta-Bars-Full {
    
        border-radius: 7px;
    
    }
    
    .Opta .Opta-TimeControls .Opta-PeriodButtons button.Opta-On,
    .Opta .Opta-TimeControls .Opta-PeriodButtons button.Opta-On:hover {
        background-color: #ff5f00;
        color: #f5f5f5;
        border-radius: 10px;
        font-weight: bold;
    }
    
    .Opta .Opta-TimeControls .Opta-PeriodButtons button {
        background-color: #E85720;
        color: #f5f5f5;
        border-radius: 10px;
        font-weight: bold;
    }
    
    .Opta-Cf .Opta-js-main {
        background: #FFFFFF
    }
    
    .Opta .Opta_F_MP div.Opta-Dropdown {
        padding-left: 10px;
        padding-right: 10px;
    }
    
    .Opta .Opta-TimeControls .Opta-PeriodButtons {
    
        background: white;
    }
    
    .Opta .Opta-Stats-Bars .Opta-Bars-Full {
    
        height: 6px;
    
    }

    .Opta.Opta-Narrow .Opta-Nav a {
        border-radius: 0px; 
         margin: 0px; 
         padding: 0xp 16px;
    }

    .Opta .Opta-Dropdown>.Opta-Nav li a {
        width: 100%;
    }
    .Opta.Opta-Narrow .Opta-Nav a {
        // border-radius: 9px;
        font-weight: 12px;

        
    }

    .Opta.Opta-Narrow .Opta-Nav a {
        // border-radius: 9px;
        font-size: 13px;
        font-weight: 700;
    }

    .Opta tbody th {
        color: black;
    }
    .Opta .Opta-Stats-Bars th {
        font-size: 14px;
        position: relative;
        top: 10px;
    }

    .Opta .Opta-Stats-Bars th {
        padding: 25px 6px 0px 6px;
    }

    .Opta .Opta-Stats-Bars th {
        height: 10px;
        padding-top: 10px;
    }

    .Opta .Opta-Stats-Bars td.Opta-Outer {
        width: 10%;
    }

    .Opta td {
        
        height: 0px;
        
    }

    .Opta-Outer {
        color: black !important;
        font-weight: 400 !important;
    }

    .Opta div.Opta-Dropdown:last-child {
        padding: 10px;
        -webkit-tap-highlight-color: transparent;
    }


    .Opta .Opta-Stats-Radial .Opta-Info {
        display: none;
    }

    .Opta h3>span{
        padding: 0px 20px;
    }


    .Opta .Opta-Dropdown>.Opta-Nav li a:hover {
        background-color: transparent;
        will-change: filter;
    }

    .Opta .Opta-Dropdown>.Opta-Nav li:nth-child(even) a:hover {
        background-color: transparent;
        will-change: filter;
    }

    .Opta .Opta-Dropdown>.Opta-Nav .Opta-Exp {
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
    }

    //Adi changes dropdown design
    .Opta .Opta-Nav {
        background-color: #fff;
    }


    .Opta .Opta-Dropdown>.Opta-Nav li:nth-child(even) a {
        background-color: #fff;
    }

    .Opta .Opta-Dropdown>.Opta-Nav li a {
        background-color: #f5f5f5;
    }

    .Opta-Open {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .Opta-Exp {
        border-radius: 10px;
    }

    .Opta .Opta-Dropdown>.Opta-Nav ul {
        border: 1px solid #f5f5f5;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    -webkit-box-shadow: 3px -2px 6px rgb(0 0 0 / 10%);
    }

    .Opta .Opta-Nav {
        position: relative;
        background-color: #fff;
        padding: 5px 7px 15px;
    }

    .Opta h3 {
       
        height: 50px;
        
    }

    .Opta-Title{
        position: relative;
        top: 3px;
    }

    .Opta .Opta-Exp .Opta-Icon:after {
        
        top: calc(50% - 4px);

    }
    .Opta-TabbedContent{
        padding: 10px;
    }

    .Opta>.Opta_W {
        
        margin: 0;
        
    }

    .Opta-error{
        color: white;
        background-position: center center;
        background-image: url(../assets/images/football_error.svg);
        background-repeat: no-repeat;
        background-size: auto;
        height: 100vh;
        }

        .Opta p:last-child {
            
            color: white;
        }

        .Opta-error::after{
            content: "Uh oh!\nOur crew has returned exhausted and unsuccessful. Please try again.";
            font-size: 20px;
            position: relative;
            top: 500px;
            color: #E85720;
            left: 140px;
            font-weight: bold;
            }

}