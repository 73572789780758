.matchprev{

    .Opta .Opta_F_MP .Opta-TabbedContent .Opta-TabbedContent {
        background-color: white;
        border: 2px solid white;
        border-radius: 10px;
        margin: 7px;
    }

    body{
        background-color: #F2F2F2;
    }

    .Opta>.Opta_W {
        // height: 100vh;
    }

    .Opta>.Opta_W {
        background: #F2F2F2;
        border: 1px solid #F2F2F2;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
       
    }

    .Opta {
       
        margin: -10px auto;
    
    }

    .Opta>.Opta_W>div {
       
        background-color: #f4f4f4;
        
    }
    
    .Opta .Opta_F_MP .Opta-Tabs .Opta-Nav.Opta-RecordNav ul li.Opta-On:after, .Opta .Opta_F_MP .Opta-Tabs .Opta-Nav.Opta-RecordNav ul li.Opta-Hover:hover:after {
        top: 89%;
    }

    .matchprev .Opta .Opta_F_MP .Opta-TabbedContent .Opta-TabbedContent .Opta-Matchdata {
        background-color: #FFFFFF;
        
    }

    .Opta .Opta_F_MP .Opta-TabbedContent table.Opta-MatchHeader .Opta-Score .Opta-Team-Score {
        background-color: #FFFFFF;
    }


    .Opta .Opta_F_MP .Opta-TabbedContent table.Opta-MatchHeader .Opta-Score {
        background: #FFFFFF;
    }
    
    .Opta .Opta_F_MP .Opta-TabbedContent table.Opta-MatchHeader .Opta-Divider {
    
        background: #FFFFFF;
    }
    
    .Opta .Opta-Tabs .Opta-Nav>ul.Opta-Overflowed:hover {
    
        display: none;
    }
    
    .Opta.Opta-Narrow .Opta_F_MP .Opta-Tabs.Opta-Home .Opta-RecordNav h3,
    .Opta.Opta-Narrow .Opta_F_MP .Opta-Tabs.Opta-Away .Opta-RecordNav h3 {
        border: none;
        background: #F2F2F2;
        color: black;
        border-radius: 0px;
    }
    
    
    .Opta .Opta_F_MP .Opta-Tabs .Opta-Nav.Opta-RecordNav ul li a {
    
        border-radius: 5px;
    
    }
    
    
    
    .Opta .Opta_F_MP .Opta-TabbedContent table.Opta-MatchHeader .Opta-Divider,
    .Opta .Opta_F_MP .Opta-TabbedContent table.Opta-MatchHeader .Opta-Score {
        background: white;
    }
    
    .Opta .Opta_F_MP .Opta-TabbedContent table.Opta-MatchHeader td.Opta-Team,
    .Opta .Opta_F_MP .Opta-TabbedContent table.Opta-MatchHeader td.Opta-Team a {
    
        background: white;
    }
    
    .Opta .Opta_F_MP .Opta-Tabs .Opta-Nav.Opta-RecordNav ul {
        margin-bottom: 10px;
    }
    
    .Opta .Opta_F_MP .Opta-Tabs .Opta-Nav.Opta-RecordNav ul li a {
        width: 80%;
        margin-left: 7px;
    
    }
    
    .Opta .Opta_F_MP .Opta-Tabs .Opta-Nav.Opta-RecordNav ul li {
    
        border-right: 1px solid #FFFFFF;
        border: none;
        border-left: 0px solid #FFFFFF;
    }
    
    .Opta .Opta-TabbedContent>li.Opta-On>div {
        margin: 10px;
    }

    .Opta .Opta_F_MP .Opta-Tabs .Opta-Nav.Opta-RecordNav ul {
        background-color: #F2F2F2;
        // height: 50px;
    }
    
    .Opta .Opta_F_MP .Opta-TabbedContent .Opta-TabbedContent .Opta-Matchdata {
        background-color: white;
        border-bottom: 0.1px solid grey;
        margin-top: -23px;
        margin-bottom: 14px;
    }
    
    .Opta .Opta-Tabs.Opta-TabsMore>.Opta-Nav>ul {
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }
    
    .Opta .Opta-Tabs .Opta-Nav>ul {
        background-color: white;
    }
    
    .Opta .Opta-Tabs .Opta-Nav>ul.Opta-Overflowed {
        background-color: white;
    }

    .Opta .Opta_F_MP .Opta-Events.Opta-Home span {
        float: left;
    }
    .Opta .Opta_F_MP .Opta-Events span {
       
        margin: 1px 5px 0 5px;
       
    }

    .Opta .Opta-Icon.Opta-IconPenGoal{
        background: url(../assets/images/penalty-kick.gif) no-repeat;
        background-color: white;
    background-size: contain;
    background-position: center center;
    font-size: 0px;
    margin: 0px 0px;
    }

    .Opta .Opta_F_MP .Opta-Events.Opta-Home span {
        padding: 7px;
    }

    .Opta p.Opta-Load{
        background: #f2f2f2;
        border-radius: 10px;
        border: 2px solid #f2f2f2;
        background-image: url(../assets/images/loaderTransparent.gif);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        font-size: 0px;
        padding: 70px 11px 41px 11px;
        margin: 0px 0px;
    }


}