.Opta .Opta-H2 .Opta-Title,
.Opta .Opta-H2 .Opta-Title-Extra,
.Opta h2 .Opta-Title,
.Opta h2 .Opta-Title-Extra {
    background: white;
    color: #ff8e29;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

}

.Opta h2 .Opta-Logo {
    background-image: none;
}

.Opta .Opta-H2,
.Opta h2 {

    height: 44px;
    margin-left: 18px;

}

.Opta .Opta-H2,
.Opta h2 {
    background: white;
}

.Opta .Opta-H3,
.Opta h3 {

    background: #ff993f;

    color: #000000;

    /* border-radius: 10px; */
}

.Opta abbr {

    font-weight: bold;
}

.Opta .Opta-H2>span,
.Opta .Opta-H3>span,
.Opta .Opta-H4>span,
.Opta h2>span,
.Opta h3>span,
.Opta h3>abbr,
.Opta h4>span {
    font-weight: 550;
}