.Timeline{

    .Opta .Opta_F_MS .Opta-Matchdata:nth-child(2) {
        margin-top: 10px;
        display: none;
    }
    
    .Opta .Opta_F_MS_T .Opta-Eventslist {
        background: #f5f5f5;
        border: 2px solid #f5f5f5;
        // border-radius: 10px;
        // height: 101vh;
    
    }
    
    .Opta .Opta_F_MS_T .Opta-Eventslist .Opta-verticalline {
        border: 1px solid #d7d4d4;
        width: 1px;
    }
    
    .Opta .Opta_F_MS_T .Opta-MatchEvent {
        margin-bottom: 16px;
        box-shadow: 5px 5px 10px grey;
        border-radius: 10px;
    }
    
    .Opta a {
        color: #5f5f5ff2;
    }
    
    .Opta .Opta-H2>span,
    .Opta h2>span {
    
        margin-right: 14px;
    
    }
    .Opta>.Opta_W {
        
        margin: -25px 0;
    
    }

    .Opta .Opta_F_MS_T .Opta-MatchEvent.Opta-Home.Opta-MatchEvent-narrow {
    
        justify-content: center;
    }

    // .Opta .Opta_W .Opta-Tooltip {
    //     z-index: -1;
    // }

    .Opta .Opta_W .Opta-Tooltip h3 {
        background-color: #e85720;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        display: none;
    }

    .Opta .Opta_F_MS .Opta-Events {   
        padding: 45px 0;
    }

    .Opta .Opta_F_MS_T .Opta-Eventslist {
        margin-top: 20px;
        
    }

    .Opta .Opta_F_MS_T .Opta-Eventslist .Opta-verticalline {
       
        height: calc(100% - 0px);

    }

    .Opta .Opta_W .Opta-Tooltip {
        border-radius: 10px;
        
    }

    .Opta {
        
        position: relative;
        top: 5px;
       
    }

    .Opta .Opta-Icon.Opta-IconPenGoal{
        background: url(../assets/images/penalty-kick.gif) no-repeat;
        background-color: white;
    background-size: contain;
    background-position: center center;
    font-size: 0px;
    margin: 0px 0px;
    }

    .Opta .Opta_F_MS_T .Opta-MatchEvent .Opta-Event-Time {
        font-weight: bold;
        color: black;
        font-size: 14px;
    }

    .Opta .Opta-Icon.Opta-IconGoal  {
        min-width: 25px;
        min-height: 25px;
        // animation: bounce 0.5s;
        // animation-direction: alternate;
        // animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
        // animation-iteration-count: infinite;
        background: url(../assets/images/goal.png) no-repeat;
        background-color: white;
        background-size: contain;
        background-position: center center;
        font-size: 0px;
        margin: 0px 0px;
        // animation-name: rolling;
        // animation-duration: 4s;
        // animation-timing-function: ease-in-out;
        // Animation-iteration-count: 5000;
        // animation-delay:2s;
        // /* Tells the animation what to-do outside */
        // animation-fill-mode: both;
        // animation-direction: alternate;
        }

        @keyframes rolling {
        0% {transform: translateX(0) rotate(0turn);}
        20% {transform: translateX(70px) rotate(.5turn);}
        40% {transform: translateX(0) rotate(-.2turn);}
        70% {transform: translateX(70px) rotate(2.5turn);} 
        100% {transform: translateX(0) rotate(-1.0turn);} 
        }
      
    // @keyframes bounce {
    //     from {
    //         transform: translate3d(0, -15px, 0);
    //     }
    //     to {
    //         transform: translate3d(0, 10px, 0);
    //     }
    // }
      
    // @-webkit-keyframes bounce {
    //     from {
    //         -webkit-transform: translate3d(0, 0, 0);
    //         transform: translate3d(0, 0, 0);
    //     }
    //     to {
    //         -webkit-transform: translate3d(0, 20px, 0);
    //         transform: translate3d(0, 200px, 0);
    //     }
    // }

    .Opta .Opta-Icon.Opta-IconYellow {
        background-position: -12px -73px;
        width: 11px;
        -webkit-animation: anim 3s infinite linear;
    animation: anim 3s infinite linear;
}

.Opta .Opta-Icon.Opta-IconRed {
    -webkit-animation: anim 3s infinite linear;
    animation: anim 3s infinite linear;
}

@-webkit-keyframes anim {
    from {-webkit-transform: rotateY(0deg);}
    to {-webkit-transform: rotateY(360deg);}
}

@keyframes anim {
    from {transform: rotateY(0deg);}
    to {transform: rotateY(360deg);}
}

.Opta .Opta_F_MS_T .Opta-MatchEvent.Opta-Home {
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Opta .Opta_F_MS_T .Opta-MatchEvent.Opta-Away {
    left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Opta .Opta_F_MS_T .Opta-Tooltip>div>div .Opta-Icon-tooltip {
    margin-left: 40px;
    float: right;
    position: relative;
    top: 9px;
    left: -5px;
}

.Opta .Opta_F_MS_T .Opta-MatchEvent.Opta-Home>.Opta-Event-player{
    margin-right: 27px;
}

.Opta .Opta_F_MS_T .Opta-MatchEvent.Opta-Away>.Opta-Event-player{
    margin-left: 27px;
}

.Opta .Opta_F_MS_T .Opta-MatchEvent .Opta-Event-Time {
   
    width: 58px;
}

.Opta .Opta_F_MS_T .Opta-Tooltip>div>div {
    display: inline-flex;
    flex-direction: column;
}

.Opta .Opta_F_MS .Opta-Tooltip div>div p.Opta-soft {
    font-weight: bold;
}

.Opta .Opta_F_MS .Opta-Tooltip div>div p {
    bottom: 15px;
    font-size: 15px;
    left: 5px;
    padding-top: 0px;
    margin-top: -13px;
    position: relative;
}

.Opta .Opta_F_MS .Opta-Tooltip div>div img {
    border-radius: 5px;
    position: relative;
    right: 20px;
}

//tooltip

.Opta .Opta_W .Opta-Tooltip>div {
   
    padding-left: 20px;
    padding-top: 13px;
    
}
.Opta .Opta-Tooltip .Opta-Tooltip-Bottom{
    top: 83.3984px;
}

.Opta-Event-Reason{
        position: relative;
        top: -5px
}

.Opta p.Opta-Load {
    position: relative;
    top: -6px;
}

.Opta>.Opta_W>div {
    
    padding: 0 0 0px 0;
}

}