.privacy-policy-page{
    p{
        font-family: 'Poppins', sans-serif;
        color: #6E6E6E;
        font-size: 15px;
        padding-bottom: 10px;
    }

    strong{
        color: #0A1015;
        position: relative;
        top: 20px;
        font-size: 14px;
    }

    .first{
        position: relative;
        top: -20px;
    }
}