.Opta .Opta-H2 .Opta-Title,
.Opta .Opta-H2 .Opta-Title-Extra,
.Opta h2 .Opta-Title,
.Opta h2 .Opta-Title-Extra {
    background: white;
    color: #ff8e29;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

}

.Opta h2 .Opta-Logo {
    background-image: none;
}

.Opta .Opta-H2,
.Opta h2 {

    height: 44px;
    margin-left: 18px;

}

.Opta .Opta-H2,
.Opta h2 {
    background: white;
}

.Opta .Opta-MatchHeader tr.Opta-MatchHeader-Details td>div,
.Opta .Opta-MatchHeader div.Opta-MatchHeader-Details>div {

    background-color: white;
}

.Opta .Opta-Matchdata:first-of-type {

    background: white;
}

.Opta .Opta-Matchdata dt {
    display: inline-block;
    font-weight: bold;
}

.Opta .Opta-MatchHeader tr.Opta-MatchHeader-Details td span,
.Opta .Opta-MatchHeader div.Opta-MatchHeader-Details span {

    font-weight: bold;
}

.Opta .Opta-MatchHeader tr.Opta-Score-Extras td {

    display: none;
}


.Opta_F_TL.Opta_F_TL_N .Opta-vertical .Opta-block {
    display: none;
}

.Opta-TeamFormation {
    display: none;
}