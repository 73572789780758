.Opta .Opta-Stats-Radial .Opta-Stat path.Opta-Arc {
    fill: #E85720;
}

.Opta .Opta-Stat .Opta-Value {
    fill: #E85720;
    color: #E85720;
}

.Opta .Opta-Stats-Cross .Opta-Stat circle {
    fill: #E85720;
}

.Opta .Opta-Stats-Goal-Methods .Opta-Goal-Figure {
    filter: invert(28%) sepia(50%) saturate(2940%) hue-rotate(257deg) brightness(79%) contrast(156%);
}

.Opta .Opta-Stats-Bars.Opta-Stats-Bars-Simple .Opta-Bar-Track .Opta-Bar-Fill {

    background: #E85720;
}

.Opta .Opta-Nav li {
    border: none;
}

.Opta.Opta-Narrow .Opta-Nav a {
    font-size: 9px;
    margin: 4px;
    border-radius: 9px;
    font-weight: bold;
}