.teamComparison{

    body{
        background-color: #f5f5f5;
    }

.Opta .Opta-Tabs .Opta-Nav>ul {
    display: none;
}

.Opta .Opta-Selects {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
    
}

.Opta.Opta-Narrow .Opta_F_TC_D .Opta-Selects .Opta-Home {
    margin-bottom: 0px;
    
}

.Opta.Opta-Narrow .Opta_F_TC_D .Opta-Selects .Opta-Away {
    width: 100%;
    margin: 0;
    align-items: center;
    position: relative;
    left: 9px;
}

.Opta .Opta_F_TC_D .Opta-Cf {
    background: #f5f5f5;
    border-radius: 10px;

}

.Opta .Opta_F_TC_D .Opta-Selects>div.Opta-Away {
    border: none;
}

.Opta .Opta_F_TC_D .Opta-Selects>div {
    border: none;
}

.Opta .Opta-Image-Team-Medium {
    max-width: 32px;
    max-height: 32px;
    left: 12px;
    position: relative;
}

.teamComparison .Opta .Opta_F_TC_D .Opta-Selects .Opta-Away .Opta-Crest {
    left: 40px;
    position: relative;
}
.Opta .Opta_F_TC_D .Opta-Selects .Opta-Away p {
    text-align: right;
    position: relative;
    top: 7px;
    right: 5px;
}

.Opta .Opta-Odd th{
    background-color: #f5f5f5;
}

.Opta .Opta-Stats-Bars td.Opta-Outer {
    width: 20%;
    color: black;
    font-size: 14px;
    font-weight: bold;
}

.Opta .Opta-Comparison tbody th.Opta-label {
    font-size: 13px;
}

.Opta.Opta-Narrow .Opta_F_TC_D .Opta-Selects .Opta-Home p{
    position: relative;
    top: 7px;
    left: 11px;
}
.teamComparison .Opta.Opta-Narrow .Opta_F_TC_D .Opta-Selects .Opta-Away {
    position: relative;
    left: 14px;
}

.Opta .Opta-Odd td{
    background-color: #f5f5f5;
}

.Opta .Opta_F_TC_D .Opta-Selects .Opta-Away .Opta-Crest {
    position: relative;
    left: 52px;
}

.Opta>.Opta_W {
    background: #f5f5f5;
    border-radius: 10px;
    
}

.Opta p.Opta-Load {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding-top: 10px;
}

.Opta.Opta-Narrow .Opta_F_TC_D .Opta-Selects .Opta-Dropdown-Content {
    min-width: auto;
    
}

}