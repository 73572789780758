.player{

    background-color: #0A1015;
    height: 100vh;

    .Opta>.Opta_W>div {
        background-color: #0A1015;
        color: #0A1015;
        font-weight: bold;
        display: flex;
        justify-content: center;
    }

    .Opta p.Opta-Load {
        background-color: #0A1015;
    }
    .Opta p.Opta-Load {
        padding: 780px 11px 41px;
    }

.Opta .Opta-Nav a {
    line-height: 42px;
    font-weight: bold;
}

.Opta.Opta-Narrow .Opta-Nav a {
    border-radius: 0px;
    color: #464646;
    background: #fff;
    height: 40px;
    font-size: 13px;
}

.Opta .Opta-Nav li.Opta-On a {
    background-color: #fff;
    color: #E85720;
}

.Opta .Opta-Tabs.Opta-TabsMore>.Opta-Nav>ul {
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Opta .Opta-Nav li.Opta-On a:hover {
    background-color: white;
}

.Opta .Opta-Tabs.Opta-TabsMore>.Opta-Nav>ul {
    height: 50px;
}

// .Opta .Opta-TabbedContent>li.Opta-On>div {
//     margin: 0px;
//     border: 20px solid black;
//     padding: 0px;
// }

.Opta-Narrow .Opta_F_PP .Opta-Profile dt {
    color: white;
    font-weight: bold;
}

.Opta .Opta_W .Opta-Profile {
    color: #9D9FA1;
    background-color: #0A1015;
}

.Opta .Opta-Image-Player-Large {

    border-radius: 10px;

}

.Opta .Opta_F_PP.Opta_F_PP_N .Opta-Imaged td.Opta-Image {

    background: black;
    padding-top: 19px;
    padding-left: 12px;
}

.Opta .Opta_W .Opta-Profile .Opta-One-Profile tr:first-child td {
    border-top: 0px solid transparent;
    background-color: transparent;
}

.Opta>.Opta_W {

    margin: 0;

}

.Opta h2 .Opta-Title {
    background-color: #0A1015;
    margin-left: 20px;
    color: #FFFFFF;
    text-align: center;
}

.Opta h2 {
    margin-left: 0px;
    border: none;
    background-color: #0A1015;
}

.Opta-Flex .Opta-Stats-Section-Distribution-Mixed {
    border-radius: 10px;
} 

// .Opta .Opta-Nav {
//     margin-left: 18px;
//     margin-top: 11px;
//     margin-bottom: 11px;
//     width: 91%;
// }

.Opta .Opta-Dropdown>.Opta-Nav h3 {
    background-color: #E85720;
    border: none;
    -webkit-tap-highlight-color: transparent;
}

.Opta .Opta-Dropdown>.Opta-Nav ul {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    border: 1px solid white;
    display: none;
    position: relative;
    top: -6px;
    background: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.Opta.Opta-Narrow .Opta-Nav a {
    margin: 0px;
}


.Opta .Opta-Nav {
    -webkit-tap-highlight-color: transparent;
    border: 20px solid #0a1015;
   
}

.Opta .Opta-Nav {
    
    background-color: #0A1015;
}
.Opta-Exp{
    border-radius: 10px;
}

.Opta-Open{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.Opta .Opta-TabbedContent>li.Opta-On>div {
    border: 15px solid #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 20px;
    padding: 0;
}

.Opta .Opta_F_SPS_N .Opta-Flex .Opta-Flex-Item {
    
    background: white;
}

.Opta div.Opta-Dropdown:last-child {

    background: #0A1015;
}

.Opta .Opta_W .Opta-Tooltip h3 {
    background-color: #E85720;
    border-radius: 0px;

}

.Opta>.Opta_W>div {
   
    padding: 11px 0 10px 0;
    
}

.Opta .Opta-Stats-Radial .Opta-Info {
    display: none;
}
.Opta .Opta-Flex {
    
    background: white;

}

.Opta h2 .Opta-Title{
    font-size: 16px;
}

.Opta .Opta_W .Opta-Profile .Opta-One-Profile tr:first-child th {
    border-top: none;
    background-color: #0a1015;
    font-weight: bold;
    color: white;
}

.Opta .Opta_F_PP.Opta_F_PP_N .Opta-Imaged th:not(.Opta-Name):first-child {
    color: white;
    font-weight: bold;
}

.Opta h2 .Opta-Title{
    display: inline-table;
    position: relative;
    left: 28px
}

.player .Opta p.Opta-Load {
    bottom: 45px;
    position: relative;
}
.player .Opta p.Opta-Load {
    padding: 843px 11px 41px;
}

.Opta .Opta-Stats-Bars.Opta-Stats-Bars-Simple .Opta-Value {
    text-align: center;
    position: relative;
    top: -1px;
}

.Opta .Opta-Stats-Bars.Opta-Stats-Bars-Simple .Opta-Graph-Title {
    text-align: start; 
    width: 139px;
}

.Opta .Opta_W .Opta-Profile th {
    width: 20%;
    position: relative;
    left: 15px;
}

.Opta td:last-child {
   
    position: relative;
    left: 0px;
}

    .Opta-error{
    color: white;
    background-position: center center;
    background-image: url(../assets/images/football_error.svg);
    background-repeat: no-repeat;
    background-size: auto;
    height: 100vh;
    position: fixed;
    background-color: #0A1015;
    top: -200px;
    }

    .Opta-error::after{
        color: #e85720;
    content: "Uh oh!\aOur crew has returned exhausted and unsuccessful. Please try again.";
    font-size: 18px;
    font-weight: 700;
    position: relative;
    top: 500px;
    text-align: center;
    white-space: break-spaces;
    display: flex;
    align-items: center;
    padding: 11px;
    justify-content: center;
        }


}